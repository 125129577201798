import React from "react"
import styled, { keyframes } from "styled-components"

export const HeroWrapper = styled.div`
  max-width: 39.875rem; /* Maintain optimal position between names */
  grid-column: 1 / -1; /* from first grid line to last */
`

const textIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(2.5vw)
  }
  100%{
    opacity: 1;
    transform: translateY(0)
  }
`

export const HeroHeading = styled.h1`
  margin: 0;
  font-size: 12vw;
  animation: ${textIn} 750ms ease-in-out forwards;
  animation-delay: 1.5s;
  opacity: 0;

  @media screen and (min-width: 45rem) {
    font-size: 6rem;
  }
`

const Italics = styled.em`
  font-style: italic;
  font-family: Ogg-Italic;
`

const TitleOffset = styled.span`
  display: block;
  text-align: right;
  animation: ${textIn} 750ms ease-in-out forwards;
  animation-delay: 1.55s;
  opacity: 0;
`

const PathTransform = styled.div`
  margin-bottom: -1.5rem;
  text-align: right;
`

const SvgCurve = styled.svg`
  /* transform: rotate(-75deg) translate(2rem, -50%); */
  transform: rotate(-60deg) translate(50%, 0);
  transform-origin: 50% center;
  animation: curveIn 500ms ease-in-out forwards;
  animation-delay: 2s;
  opacity: 0;

  @keyframes curveIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media screen and (min-width: 45rem) {
    transform: rotate(-90deg) translate(50%, 50%);

    @keyframes curveIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
`

const CurvedText = styled.text`
  display: block;
  font-size: 3vw;
  font-weight: 600;
  letter-spacing: .125rem;
  text-transform: uppercase;
  fill: ${props => props.theme.colors.primaryColor};

  @media screen and (min-width: 45rem) {
    font-size: 1.25rem;
  }
`

const HeroTitle = () => (
  <HeroWrapper>
    <HeroHeading>
      <span>Elysia <Italics>and</Italics></span>
      <TitleOffset>Samuel</TitleOffset>
    </HeroHeading>

    <PathTransform>
      <SvgCurve viewBox="0 120 120 120" height="120" width="120">
        <path id="curve" d="M0,120 a1,1 0 0,0 240,0" fill="none" />
        <CurvedText>
          <textPath xlinkHref="#curve">invite you to</textPath>
        </CurvedText>
      </SvgCurve>
    </PathTransform>
  </HeroWrapper>
)

export default HeroTitle
