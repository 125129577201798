import baseStyled, { ThemedStyledInterface } from 'styled-components';

export const lightTheme = {
  colors: {
    background: "#f2deca",
    primaryColor: "#b56351",
    accentColor: "#1574a1",
    formBackground: "hsla(17, 40%, 68%, .25)",
    formColor: "hsl(17, 40%, 45%)"
  },
};

export type Theme = typeof lightTheme;
export const styled = baseStyled as ThemedStyledInterface<Theme>;
