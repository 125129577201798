import baseStyled, { ThemedStyledInterface } from 'styled-components';

export const darkTheme = {
  colors: {
    background: "#023525",
    primaryColor: "#e2b291",
    accentColor: "#e2b291",
    formBackground: "hsla(0, 0%, 55%, .15)",
    formColor: "#e2b291"
  },
};

export type Theme = typeof darkTheme;
export const styled = baseStyled as ThemedStyledInterface<Theme>;
