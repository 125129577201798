import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import styled from "styled-components"

const HeadingLink = styled(Link)`
  color: ${props => props.theme.colors.primaryColor};
  text-decoration: none;
`

const InitialMark = props => (
  <svg width={60} height={32} viewBox="0 0 59 32" {...props}>
    <g fill="currentColor">
      <path
        stroke="currentColor"
        strokeWidth={0.718}
        d="M21.673 15.856c-2.436 4.609-5.353 10.134-7.71 14.842-.516.096-.492.16-.82-.118 2.745-4.727 5.66-10.222 8.16-14.885A3482.07 3482.07 0 0029.172.477c.482-.07.45-.043.81.208-2.469 4.06-5.902 10.559-8.309 15.17z"
      />
      <path d="M13.341 11.768c2.75-2.598 3.68-4.516 2.181-5.704-1.861-1.475-4.938.757-6.62 4.036-.775 1.51-1.114 2.979-.975 4.265-2.139 1.609-4.769 3.444-7.887 5.501l.777 1.22c2.935-1.936 5.445-3.68 7.53-5.23.545 1.116 1.592 1.986 3.184 2.465 3.49 1.049 8.388-.005 14.096-2.498 2.984-1.304 6.33-3.131 7.984-4.187.032.35.056.784.068 1.282.043 1.698-.044 3.877-.256 5.972-.032.324-.068.643-.106.956-1.394 1.31-2.77 2.902-3.5 4.27-.742 1.392-1.087 2.774-1.009 3.862.09 1.253.808 2.133 1.868 1.765 2.074-.72 3.336-4.135 3.993-9.178.685-.616 1.355-1.143 1.915-1.503 1.057-.68 2.394-1.497 4.187-2.566.706-.42 1.454-.863 2.477-1.467l2.587-1.527.598-.353c3.918-2.321 6.424-3.86 8.337-5.158 1.169-.793 2.036-1.447 2.604-1.975.87-.81 1.242-1.456.686-2.123l-1.09.941c-.138-.166-.136-.324-.114-.383l-.014.02a2.98 2.98 0 01-.432.471c-.502.467-1.32 1.083-2.433 1.839-1.88 1.276-4.368 2.804-8.263 5.11l-.596.353-2.586 1.526a649.132 649.132 0 00-2.483 1.47 151.092 151.092 0 00-4.227 2.592c-.289.185-.6.407-.925.659.18-1.983.25-3.998.21-5.61-.02-.824-.07-1.5-.145-1.964-.045-.277-.1-.49-.18-.66-.216-.453-.728-.742-1.233-.332-.242.195-1.399.92-2.688 1.65a63.189 63.189 0 01-5.797 2.911c-5.423 2.369-10.051 3.365-13.128 2.44-1.26-.379-2.048-1.064-2.399-1.97 1.541-1.186 2.809-2.249 3.804-3.188zm16.982 16.555a1.515 1.515 0 01-.09-.45c-.057-.79.22-1.902.84-3.063.418-.785 1.1-1.676 1.878-2.535-.608 3.302-1.536 5.55-2.628 6.048zm-20.155-17.55c1.324-2.58 3.617-4.243 4.477-3.561.414.328-.141 1.474-2.275 3.49-.812.766-1.819 1.622-3.019 2.568.083-.762.359-1.603.817-2.496z" />
    </g>
  </svg>
);

const Identifier = styled(InitialMark)`
  position: fixed;
  top: 2.5rem;
  left: 2.5rem;
  color: ${props => props.theme.colors.primaryColor};
`

const StickerText = styled.span`
  position: fixed;
  font-size: .875rem;
  font-weight: 600;
  letter-spacing: .05rem;
`

const StickerTag = styled(StickerText)`
  bottom: 5rem;
  left: 3.25rem;
  transform: rotate(-90deg);
  transform-origin: 0 0;
`

const StickerDate = styled(StickerText)`
  top: 2.5rem;
  right: calc(-6rem + 2.5rem);
  transform: rotate(90deg);
  transform-origin: 0 0;
`

const Header = ({ siteTitle }) => (
  <>
    <Link to="/">
      <Identifier></Identifier>
    </Link>
    <StickerTag>#kwakanalia</StickerTag>
    <StickerDate>09﹒27﹒2020</StickerDate>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
