/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled, { keyframes, ThemeProvider } from "styled-components"

import { lightTheme } from "../themes/light"
import { darkTheme } from "../themes/dark"

import setBodyColor from "../themes/setBodyColor"

import Header from "./header"
import "./layout.css"
import "./fonts.css"

const ThemedLayout = styled.div`
  color: ${props => props.theme.colors.primaryColor};
`

export const animateLine = keyframes`
  0% {
    background-size: 0% .125rem;
    background-position: 0 100%;
  }
  25% {
    background-size: 100% .125rem;
  }
  50% {
    background-size: 100% .125rem;
    background-position: -100% 100%;
  }
  75%, 100% {
    background-size: 200% .125rem;
    background-position: -100% 100%;
  }
`

export const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.primaryColor};
  font-weight: 600;
  text-decoration: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 0% .125rem;

  &:hover {
    background-size: 100% .125rem;
    animation: ${animateLine} 1s ease-in-out infinite forwards;
  }
`

const Container = styled.main`
  display: grid;
  grid-template-columns: [gutter-left] 1fr [main] 2fr [offset-right] 6fr [gutter-right] 1fr;
  /* grid-template-columns: [gutter-left] 1fr [main] 8fr [gutter-right] 1fr; */
  min-height: 100vh;
  background-color: ${props => props.theme.colors.background};

  @media screen and (min-width: 65rem) {
    grid-template-columns: [gutter-left] 2fr [main] 6fr [offset-right] 2fr [gutter-right] 2fr;
  }
`

const Section = styled.div`
  grid-column: main / gutter-right;
`

export const DetailSection = styled(Section)`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
`

const Layout = ({ children }) => {
  const mode = typeof window !== 'undefined' && window.matchMedia('(prefers-color-scheme: light)');
  const defaultTheme = mode.matches ? lightTheme : darkTheme;
  setBodyColor(defaultTheme.colors.background);
  const [theme, setTheme] = useState(defaultTheme);

  if (mode) {
    mode.addEventListener('change', (e) => {
      if (e.matches) {
        setTheme(lightTheme);
        setBodyColor(lightTheme.colors.background);
      } else {
        setTheme(darkTheme);
        setBodyColor(darkTheme.colors.background);
      }
    })
  }

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title 
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <ThemedLayout theme={theme}>
        <Header siteTitle={data.site.siteMetadata.title} />
        <Container>
          {children}
          {/* <footer>
            © {new Date().getFullYear()}, Built with
            {` `}
            <a href="https://www.gatsbyjs.org">Gatsby</a>
          </footer> */}
        </Container>
      </ThemedLayout>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
