import React from "react"
import styled, { keyframes } from "styled-components"

export const textIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(2.5vw)
  }
  100%{
    opacity: 1;
    transform: translateY(0)
  }
`
interface RevealProps {
  delay?: string;
}

const RevealBlock = styled.span<RevealProps>`
  opacity: 0;
  animation: ${textIn} 1s ease-in-out forwards 1s;
  animation-delay: ${props => props.delay || 'auto'};
`


const Reveal = ({ delay, children }) => (
  <RevealBlock delay={delay}>
    {children}
  </RevealBlock>
)

export default Reveal
